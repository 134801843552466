.service-container {
}
.service-card {
  background-color: #222 !important;
  color: #fff;
}
.service-card-title {
  font-size: 27px !important;
}

.card {
  height: 100%;
}

.service-container {
  margin-top: 20px;
}

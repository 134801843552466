.blog-card {
  background-color: #222 !important;
  color: #fff;
  cursor: pointer;
}
.blog-card-title {
  font-size: 27px !important;
}

.card {
  height: 100%;
}

.blog-container {
  margin-top: 20px;
}

.intro {
  height: 100vh;
  min-height: 100vh;
  justify-content: center;
}
.introCon {
  display: flex;
  flex-direction: column;
}
.intro-container {
  height: 100%;
}
.parallax {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  overflow: hidden;
}

.imgCon {
  width: 100px;
  height: 100px;
}

.logoX {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  border-radius: 50%;
}

.nameCon {
  font-family: "Samarkan";
  font-size: 36px;
  color: #fff;
  font-weight: 700;
}

.intro-txt-con {
  color: #fff;
  font-size: 18px;
}

.scrollToNextContainer {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin-top: 40px;
  font-size: 50px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
  animation: scrollDown 2s infinite;
}

@keyframes scrollDown {
  0% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}

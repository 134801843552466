@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
    url(./assets/fonts/Rubik/Rubik-VariableFont_wght.ttf) format("truetype");
}
@font-face {
  font-family: "Samarkan";
  src: local("Samarkan"),
    url(./assets/fonts/samarkan/samarkan.TTF) format("truetype");
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111 !important;
  height: 100vh !important;
}

iframe {
  position: relative !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.align-items-center {
  align-items: center;
}

.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  background-color: #111;
}

.nav-link {
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 700 !important;
}

.nav-head .nav-link {
  color: #fff !important;
}

.active {
  color: #fff;
}

.nav-head .link-active {
  color: #ffc107 !important;
}

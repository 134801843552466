.about-col {
  background-color: #222;
  padding: 25px;
  border-radius: 20px;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.about-col p {
  color: #fff;
  line-height: 1.7;
  font-size: 16px;
}

.avatar-2 {
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.about-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.skill-con {
  color: #fff;
}

.skill-con .progress {
  height: 0.5rem;
}

.progress-head {
  display: flex;
}

.heading {
  color: #fff;
}

.section-title {
  font-weight: 700;
}

.section-title::before {
  content: "";
  background-image: url(../assets/imgs/dots-bg-light.svg);
  display: block;
  height: 37px;
  left: -5px;
  top: -14px;
  position: absolute;
  width: 37px;
}

.about-txt {
  font-size: 40px;
}

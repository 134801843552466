.header {
  /* padding: 10px 0px; */
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

main {
  height: 100vh !important;
}

.section-intro {
  height: 100vh;
  min-height: 100vh;
}
.section-about {
  padding-top: 70px;
}

.section-services {
  padding-top: 70px;
}

.section-blog {
  padding-top: 70px;
}

.section-footer {
  padding-top: 70px;
  height: 300px !important;
}

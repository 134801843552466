$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;
$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@media only screen and (max-width: 768px) {
  .triangle-top-sm {
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #222;
      position: absolute;
      left: 50%;
      top: -10px;
      transform: translateX(-7.5px);
    }
  }
}

@media only screen and (min-width: 768px) {
  .triangle-left-md {
    &:before {
      content: "";
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-right: 15px solid #222;
      position: absolute;
      left: -15px;
      top: 20%;
    }
  }
  .container {
    max-width: 920px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 920px;
  }
}
@media only screen and (min-width: 1366px) {
  .container {
    max-width: 1080px;
  }
}
@media only screen and (min-width: 1200px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media only screen and (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

.spacer {
  height: 60px;
}
@import "~bootstrap/scss/bootstrap.scss";
